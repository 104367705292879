import { useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { spsQueries } from '@qb/httpRequest/spsQueries';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { SearchPartAutocompleteOmniResponse } from '@/shared/types/controllers/SearchControllerTypes';

const MANUFACTURERS_LIMIT = 3;
export const useManufacturersAutocompleteRequest = (
  query: string,
  tenantAlias: TenantAlias,
) => {
  const { isLoading: areManufacturersLoading, data: manufacturers = [] } =
    useQuery({
      ...spsQueries.search.partAutocomplete(tenantAlias, query),
      keepPreviousData: true,
      staleTime: minutesToMilliseconds(1),
      select: getUniqueManufacturers,
      enabled: Boolean(query),
    });

  return {
    areManufacturersLoading,
    manufacturers: manufacturers.slice(0, MANUFACTURERS_LIMIT),
  };
};

const getUniqueManufacturers = (data: SearchPartAutocompleteOmniResponse) => {
  const { parts } = data;
  const manufacturerMap = new Map(
    parts.map((part) => [part.manufacturer.id, part.manufacturer]),
  );
  return Array.from(manufacturerMap.values());
};
