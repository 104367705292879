'use client';

import { useCallback } from 'react';
import Image from 'next/image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Card, CardActionArea, CardActions, Chip, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@qb/frontend/components/LoadingButton';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents } from '@qb/frontend/utils/formatCurrency';
import { getFirstImage } from '@qb/frontend/utils/getFirstImage';
import { PartPopularPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
import { PartCardShipProcessingDays } from './components/PartCardShipProcessingDays';

// user doesn't have the option to set qty in this component
const BUY_NOW_QTY = 1;
type PartCardLargeProps = {
  part: PartPopularPartsDataResponse['parts'][0] & {
    shipProcessingDays?: number;
  };
  onClick?: () => void;
};
export const PartCardLarge = ({
  part,
  onClick
}: PartCardLargeProps) => {
  const onHandQty = part.onHandQty ?? 0;
  const isMarketablePart = onHandQty > 0 && !!part.price;
  const href = generatePartURL(part.manufacturer.name, part.partNumber, part.id);
  const onPartView = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);
  return <Card sx={{
    backgroundColor: 'background.paper',
    border: '2px solid',
    borderColor: 'primary.50p',
    overflow: 'hidden',
    borderRadius: 0
  }} data-sentry-element="Card" data-sentry-component="PartCardLarge" data-sentry-source-file="PartCardLarge.tsx">
      <CardActionArea onClick={onPartView} href={href} tabIndex={-1} sx={{
      px: 2,
      pt: 1,
      pb: isMarketablePart ? 0.5 : 1.5
    }} data-sentry-element="CardActionArea" data-sentry-source-file="PartCardLarge.tsx">
        {!!part.onHandQty && !!part.price && <PartCardShipProcessingDays shipProcessingDays={part.shipProcessingDays} />}
        <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
      }} data-sentry-element="Box" data-sentry-source-file="PartCardLarge.tsx">
          <Image src={getFirstImage(part.images)} alt={`${part.partNumber} Part Image. Manufactured by ${part.manufacturer.name}.`} width={222} height={90} style={{
          objectFit: 'contain'
        }} data-sentry-element="Image" data-sentry-source-file="PartCardLarge.tsx" />
        </Box>
        <Typography variant="h4" component="h3" sx={{
        my: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }} data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.partNumber}
        </Typography>
        <Stack data-sentry-element="Stack" data-sentry-source-file="PartCardLarge.tsx">
          {!!part.price && <Typography variant="bodyLargePrimary" fontWeight={600} color="text.primary" sx={{
          mb: 1
        }}>
              {formatCents(part.price)}
            </Typography>}
        </Stack>
        <Chip icon={<CheckCircleIcon />} color="success" size="small" label={<>
              Verified stock: <strong>{onHandQty.toLocaleString()}</strong>
            </>} sx={{
        width: 'fit-content',
        mb: 1.5,
        mt: 0.5,
        visibility: onHandQty ? 'visible' : 'hidden'
      }} data-sentry-element="Chip" data-sentry-source-file="PartCardLarge.tsx" />
        <Typography variant="bodyMedium" sx={{
        mb: 1,
        height: 51,
        ...clampLinesSx(3)
      }} data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.description}
        </Typography>
        <Typography fontWeight={600} color="text.primary" data-sentry-element="Typography" data-sentry-source-file="PartCardLarge.tsx">
          {part.manufacturer.name}
        </Typography>
      </CardActionArea>

      {isMarketablePart && <CardActions sx={{
      mb: 1
    }}>
          <LoadingButton fullWidth variant="contained" sx={{
        whiteSpace: 'nowrap'
      }} loading={false} tabIndex={-1} onClick={() => {
        window?.salesPilotPostMessages?.buyNow({
          qty: BUY_NOW_QTY,
          partID: part.id
        });
      }}>
            Buy now
          </LoadingButton>
        </CardActions>}
    </Card>;
};