'use client';

import { range } from 'lodash';
import { List, ListItem, Skeleton } from '@mui/material';
import { PartCategoryResult } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/Categories/PartCategoryResult';
import { ViewAllCategoriesButton } from '@qb/frontend/components/OmniSearch/_components/ViewAllCategoriesButton';
import { useSearchCategoriesRequest } from '@qb/frontend/components/OmniSearch/_hooks/useSearchCategoriesRequest';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
type DefaultResultsProps = TenantAliasProps & {
  onResultClick?: () => void;
};
export const NoQuery = ({
  onResultClick,
  tenantAlias
}: DefaultResultsProps) => {
  const {
    searchCategories,
    areSearchCategoriesLoading
  } = useSearchCategoriesRequest(tenantAlias);
  return <>
      <List data-sentry-element="List" data-sentry-source-file="NoQuery.tsx">
        {!areSearchCategoriesLoading && searchCategories ? searchCategories.map(searchCategory => <ListItem key={searchCategory.id}>
                <PartCategoryResult partCategory={searchCategory} onClick={onResultClick} />
              </ListItem>) : range(15).map(i => <ListItem key={i}>
                <Skeleton sx={{
          width: 200
        }} />
              </ListItem>)}
      </List>
      <ViewAllCategoriesButton sx={{
      width: 'fit-content',
      ml: 2
    }} data-sentry-element="ViewAllCategoriesButton" data-sentry-source-file="NoQuery.tsx" />
    </>;
};