import { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

type UseTypewriterEffectParams = {
  text: string;
  isEnabled?: boolean;
};

export const useTypewriterEffect = ({
  text,
  isEnabled = false,
}: UseTypewriterEffectParams) => {
  const elementRef = useRef<HTMLElement>(null);
  const intersection = useIntersection(elementRef, {
    root: null,
    threshold: 0.3,
  });
  const [textToDisplay, setTextToDisplay] = useState('');

  useEffect(() => {
    if (!isEnabled || !intersection?.isIntersecting) {
      return;
    }

    let index = 0;

    const animateText = () => {
      setTextToDisplay(text.substring(0, index));
      index += 1;

      if (index > text.length) {
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(animateText, 100);

    return () => clearInterval(intervalId);
  }, [isEnabled, intersection?.isIntersecting, text]);

  return { textToDisplay, elementRef };
};
