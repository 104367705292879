import { useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { spsQueries } from '@qb/httpRequest/spsQueries';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { SearchPartAutocompleteResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';

const CATEGORY_LIMIT = 3;

/* This query uses primarily categories from partCategoryAutocompleteOmni, but if there are not
 * enough hits, it will use categories from the partAutocompleteOmni parts */
export const usePartCategoriesAutocompleteRequest = (
  query: string,
  tenantAlias: TenantAlias,
) => {
  const isEnabled = Boolean(query);
  const {
    isLoading: arePartCategoriesLoading,
    data: partCategoriesFromCategorySearch = [],
  } = useQuery({
    ...spsQueries.search.partCategoryAutocomplete(tenantAlias, query),
    keepPreviousData: true,
    staleTime: minutesToMilliseconds(1),
    select: (data) => data.partCategories,
    enabled: isEnabled,
  });

  const { data: partCategoriesFromParts = [] } = useQuery({
    ...spsQueries.search.partAutocomplete(tenantAlias, query),
    keepPreviousData: true,
    staleTime: minutesToMilliseconds(1),
    select: getUniquePartCategories,
    enabled:
      isEnabled && partCategoriesFromCategorySearch.length < CATEGORY_LIMIT,
  });

  const partCategories = [
    ...partCategoriesFromCategorySearch,
    ...partCategoriesFromParts,
  ].slice(0, CATEGORY_LIMIT);

  return {
    partCategories,
    arePartCategoriesLoading,
  };
};

const getUniquePartCategories = (data: SearchPartAutocompleteResponse) => {
  const { parts } = data;
  const categoryMap = new Map(
    parts.map((part) => [part.partCategory.id, part.partCategory]),
  );

  return Array.from(categoryMap.values());
};
