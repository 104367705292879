import Image, { ImageProps } from 'next/image';
import { Box, SxProps, Theme } from '@mui/material';
import noImageImg from '@qb/frontend/icons/noImage.svg';
type AspectRatioImageProps = ImageProps & {
  sx?: SxProps<Theme>;
  bgColorFill?: string;
};

// Preserves the aspect ratio of the image within specified width and height.
export const PreserveAspectRatioImage = ({
  sx = {},
  // Use to fill available space with this color
  bgColorFill,
  ...imageProps
}: AspectRatioImageProps) => {
  const {
    width,
    height,
    src,
    alt,
    ...restImageProps
  } = imageProps;
  return <Box sx={{
    width,
    height,
    bgcolor: bgColorFill ?? 'initial',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    ...sx
  }} data-sentry-element="Box" data-sentry-component="PreserveAspectRatioImage" data-sentry-source-file="PreserveAspectRatioImage.tsx">
      <Image src={src || noImageImg} alt={alt} style={{
      objectFit: 'contain'
    }} fill sizes={`${width}px`} {...restImageProps} data-sentry-element="Image" data-sentry-source-file="PreserveAspectRatioImage.tsx" />
    </Box>;
};